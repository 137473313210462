
import sidebar from '~/mixins/sidebar'

export default {
  mixins: [sidebar],

  props: {
    data: {
      type: Object,
      required: true,
    },

    category: {
      type: Object,
      default: null,
    },
  },
}
